import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import styled from "styled-components";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { MdOutlineStarOutline, MdStarHalf } from "react-icons/md";
import {
  CalendarToday,
  MyLocation,
  Star,
  SupervisorAccount,
} from "@material-ui/icons";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { Swiper, SwiperSlide } from "swiper/react";
import UserProfile from "../assets/user_1.jpg";
import SchoolIcon from "@mui/icons-material/School";
import { IconButton } from "@mui/material";
import { GiPostOffice } from "react-icons/gi";
import { IoTimeSharp } from "react-icons/io5";
import { Navigation, Autoplay, Pagination } from "swiper/modules";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { formattedDate, MyAPI } from "../utils/MyAPI";
import Loader from "../components/Loader";
function TeacherPublicProfile() {
  const [activeDropdown, setActiveDropdown] = useState(null);
  const { id } = useParams();
  const [teacher, setTeacher] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchTeacher = async (id) => {
    try {
      setLoading(true);
      let res = await MyAPI.GET(`/home/teacher/${id}`);
      let { res_type, message, data: apiData } = res.data || res;
      if (res_type === "success") {
        setTeacher(apiData.teacher);
      } else {
        toast.error(message || "Error fetching teacher.");
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchTeacher(id);
    }
  }, [id]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div
        className="main d-block mx-auto"
        style={{ maxWidth: "1400px", overflowX: "hidden" }}
      >
        <Navbar />
        <br />
        <br />
        <br />
        <br />
        <br />

        <Container className="mt-5 bg-white shadow rounded-4 p-4">
          <Row>
            <Col md={6} className="d-flex flex-column ">
              <div className="d-flex gap-4 align-items-center">
                <div className="student-managent-student-profile n-box-shadow">
                  <img src={teacher ? teacher.image_key : UserProfile} alt="" />
                </div>
                <div className="d-flex flex-column">
                  <h5>{teacher ? teacher.visibleName : "N/A"}</h5>
                  <div className="d-flex align-items-center gap-2">
                    <Star style={{ color: "#FFD32D" }} />
                    <Star style={{ color: "#FFD32D" }} />
                    <Star style={{ color: "#FFD32D" }} />
                    <Star style={{ color: "#FFD32D" }} /> 4 Rating
                  </div>
                  <div className="mt-3">
                    <DriveFileRenameOutlineIcon /> &nbsp; 39 reviews
                  </div>
                </div>
              </div>

              <Row className="mt-5">
                <Col md={4}>
                  <b>Schools They Teach: </b>
                </Col>
                <Col md={8}>
                  {teacher
                    ? teacher?.teacher_detail_id?.pre_schools[
                        teacher?.teacher_detail_id?.pre_schools?.length - 1
                      ]?.name
                    : ""}
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={4}>
                  <b>Language : </b>
                </Col>
                <Col md={8}>
                  {teacher
                    ? teacher?.teacher_detail_id?.languages.map(
                        (lan) => `${lan}, `
                      )
                    : ""}
                </Col>
              </Row>
              {/* <Row className="mt-3">
                <Col md={4}>
                  <b>Competitive Exams: </b>
                </Col>
                <Col md={8}>JEE, NEET</Col>
              </Row> */}
            </Col>
            <Col md={6} className="p-0 m-0">
              <div className="position-relative">
                <video
                  autoPlay
                  muted
                  controls
                  height={280}
                  style={{
                    maxWidth: "520px",
                    borderRadius: "10px",
                  }}
                  className="rounded-3"
                  src={
                    teacher
                      ? teacher.introduction_video_key
                      : "https://videos.pexels.com/video-files/3252424/3252424-uhd_3840_2160_25fps.mp4"
                  }
                ></video>
              </div>
            </Col>
          </Row>

          <Row className="mt-3">
            <h5>About Me:</h5>
            <p>{teacher ? teacher.about : "Not Available."}</p>
          </Row>

          <Row className="mt-4">
            <Col md={6}>
              <div className="teacher-profile-items-item mt-2">
                <GiPostOffice size={22} />{" "}
                <span>
                  {teacher
                    ? teacher?.teacher_detail_id?.pre_schools[
                        teacher?.teacher_detail_id?.pre_schools?.length - 1
                      ]?.designation
                    : ""}
                </span>
              </div>
              <div className="teacher-profile-items-item mt-2">
                <LocalLibraryIcon /> <span>100 Classes Completed</span>
              </div>
              <div className="teacher-profile-items-item mt-2">
                <MyLocation /> <span>Lives in Mumbai</span>
              </div>
              <div className="teacher-profile-items-item mt-2">
                <IoTimeSharp size={22} /> <span>120+ Hours of Classes</span>
              </div>
              <div className="teacher-profile-items-item mt-2">
                <CalendarToday />{" "}
                <span>
                  Joined on {teacher ? formattedDate(teacher.createdAt) : ""}
                </span>
              </div>
              <div className="teacher-profile-items-item mt-2">
                <SupervisorAccount /> <span>10+ Years of Experience</span>
              </div>
              <div className="teacher-profile-items-item mt-2">
                <SchoolIcon /> <span>1000+ Students Taught</span>
              </div>

              <div className="slide_video mt-4 mb-5">
                <Swiper
                  slidesPerView={window.innerWidth > 768 ? 2 : 1}
                  spaceBetween={20}
                  modules={[Navigation, Autoplay, Pagination]}
                  grabCursor={true}
                  pagination={{ clickable: true }}
                  autoplay={{
                    delay: 4000,
                    disableOnInteraction: false,
                  }}
                  className="mySwiper pt-4 pb-5"
                >
                  <SwiperSlide>
                    <div className="slide_video_inner">
                      <img
                        height={200}
                        src="https://plus.unsplash.com/premium_photo-1679079456083-9f288e224e96?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        alt=""
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="slide_video_inner">
                      <img
                        height={200}
                        src="https://plus.unsplash.com/premium_photo-1679079456083-9f288e224e96?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        alt=""
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="slide_video_inner">
                      <img
                        height={200}
                        src="https://plus.unsplash.com/premium_photo-1679079456083-9f288e224e96?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        alt=""
                      />
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </Col>
            <Col md={6}>
              <h4>Top Reviews</h4>
              <div className="teacter-profile-top-review-itme mt-3 n-box-shadow">
                <div className="teacter-profile-top-review-itme-header">
                  <div className="left">
                    <div className="profile-image-for-review n-box-shadow">
                      <img src={UserProfile} alt="" />
                    </div>
                    <div className="profile-name-for-review">
                      <span className="name">Adam Shah</span>
                      <br />
                      <span className="d-flex align-items-center justify-content-center this-is-review-star">
                        <Star /> <Star /> <Star /> <Star /> <MdStarHalf />{" "}
                        &nbsp;{" "}
                        <div className="teacher-profile-review-date">
                          07/02/2023
                        </div>{" "}
                      </span>
                    </div>
                  </div>
                  <div className="right">
                    <MoreVertIcon />
                  </div>
                </div>
                <Col className="p-2 ">
                  Lorem ipsum dolor sit amet consectetur. Est congue blandit
                  sagittis condimentum vulputate fermentum aenean duis lacinia.
                  Nisi eu at ac ac tellus facilisis.
                </Col>
              </div>
              <div className="teacter-profile-top-review-itme mt-3 n-box-shadow">
                <div className="teacter-profile-top-review-itme-header">
                  <div className="left">
                    <div className="profile-image-for-review n-box-shadow">
                      <img src={UserProfile} alt="" />
                    </div>
                    <div className="profile-name-for-review">
                      <span className="name">Adam Shah</span>
                      <br />
                      <span className="d-flex align-items-center justify-content-center this-is-review-star">
                        <Star /> <Star /> <Star /> <Star /> <MdStarHalf />{" "}
                        &nbsp;{" "}
                        <div className="teacher-profile-review-date">
                          07/02/2023
                        </div>{" "}
                      </span>
                    </div>
                  </div>
                  <div className="right">
                    <MoreVertIcon />
                  </div>
                </div>
                <Col className="p-2 ">
                  Lorem ipsum dolor sit amet consectetur. Est congue blandit
                  sagittis condimentum vulputate fermentum aenean duis lacinia.
                  Nisi eu at ac ac tellus facilisis.
                </Col>
              </div>
              <div className="teacter-profile-top-review-itme mt-3 n-box-shadow">
                <div className="teacter-profile-top-review-itme-header">
                  <div className="left">
                    <div className="profile-image-for-review n-box-shadow">
                      <img src={UserProfile} alt="" />
                    </div>
                    <div className="profile-name-for-review">
                      <span className="name">Adam Shah</span>
                      <br />
                      <span className="d-flex align-items-center justify-content-center this-is-review-star">
                        <Star /> <Star /> <Star /> <Star /> <MdStarHalf />{" "}
                        &nbsp;{" "}
                        <div className="teacher-profile-review-date">
                          07/02/2023
                        </div>{" "}
                      </span>
                    </div>
                  </div>
                  <div className="right">
                    <MoreVertIcon />
                  </div>
                </div>
                <Col className="p-2 ">
                  Lorem ipsum dolor sit amet consectetur. Est congue blandit
                  sagittis condimentum vulputate fermentum aenean duis lacinia.
                  Nisi eu at ac ac tellus facilisis.
                </Col>
              </div>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col>
              {teacher && teacher?.teacher_detail_id?.qualifications ? (
                <Row>
                  {teacher.teacher_detail_id.qualifications.map((item) => (
                    <Col md={6} key={item._id} className="mb-4">
                      <Card>
                        <Card.Header>
                          {item.degree_name} {item.passing_year} (
                          {item.percentage}%)
                        </Card.Header>
                        <Card.Body>
                          <Card.Title>
                            {item.degree_name} - {item.level}
                          </Card.Title>
                          <Card.Text>
                            <strong>Institute: </strong> {item.institute_name}
                          </Card.Text>
                          {item.certificate_key && (
                            <Button
                              variant="primary"
                              href={item.certificate_key}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View Certificate
                            </Button>
                          )}
                        </Card.Body>
                        <Card.Footer>
                          <small className="text-muted">
                            Last updated:{" "}
                            {new Date(item.updatedAt).toLocaleDateString()}
                          </small>
                        </Card.Footer>
                      </Card>
                    </Col>
                  ))}
                </Row>
              ) : (
                <p>No qualifications available.</p>
              )}
              <h5 className="mt-4 mb-2">Special Achievement</h5>
              {teacher
                ? teacher?.teacher_detail_id?.achievements &&
                  teacher.teacher_detail_id.achievements.map((item) => (
                    <p key={item._id}>{item.title}</p>
                  ))
                : ""}
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    </>
  );
}

export default TeacherPublicProfile;
