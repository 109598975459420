import React, { useEffect, useState } from "react";
import { IoAdd } from "react-icons/io5";
import Dropzone from "./Dropzone.jsx";
import { GrCaretNext, GrCaretPrevious } from "react-icons/gr";
import { IoMdCloseCircle } from "react-icons/io";
import axios from "../../utils/axios.js";
import Swal from "sweetalert2";
import OftadehLayout from "../../components/parentComponents/OftadehLayout/OftadehLayout.jsx";
import { Row, Col, Button, Form } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import "./ParentRegistration.css";
import { useNavigate } from "react-router-dom";
import ParentFooter from "../../components/ParentFooter.js";
import PhoneInput from "react-phone-number-input";
import { countries } from "@nexisltd/country";
import { ToastContainer, toast } from "react-toastify";
import LearningDifficultiesInput from "./LearningDifficultiesInput.jsx";
import { useSelector } from "react-redux";
import { getCommonInfo, MyAPI } from "../../utils/MyAPI.js";
function ParentRegistration() {
  const [currentStep, setCurrentStep] = useState(1);
  const [HowToYouReferHere, setHowToYouReferHere] = useState("");
  const [AnySuggestion, setAnySuggestion] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [visibleName, setVisibleName] = useState("");
  const [wpNumber, setWpNumber] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [currency, setCurrency] = useState("");
  const [timezone, setTimezone] = useState("");
  const [communicationMode, setCommunicationMode] = useState("");
  const [userData, setUserData] = useState(null);

  const navigate = useNavigate();
  const token = useSelector((state) => state.token.value);

  useEffect(() => {
    const fetchParentInfo = async () => {
      try {
        const { data } = await getCommonInfo(token);
        // console.log("Parent info: ", data);
        if (data.res_type === "success") {
          setUserData(data.data);
          setFirstName(data.data.firstName);
          setLastName(data.data.lastName);
          setEmail(data.data.email);
          setPhone(data.data.phone);

          if (data.data.profileState.isRegistered === true) {
            setCurrentStep(2);
          }
          if (data.data.profileState.hasChildren === true) {
            // navigate("/parent/dashboard");
            setCurrentStep(3);
          }
        }
      } catch (error) {
        console.log("Error fetching parent info: ", error);
      }
    };
    fetchParentInfo();
  }, [token]);

  // console.log("userData:", userData);

  useEffect(() => {
    if (userData?.profileState?.isRegistered) {
      setCurrentStep(2);
    }
    if (userData?.profileState?.hasChildren) {
      navigate("/parent/dashboard");
    }
  }, [userData, currentStep]);

  // useEffect(() => {
  //   if (currentStep === 3) {
  //     navigate("/parent/dashboard");
  //   }
  // }, [userData, currentStep]);

  const initialChildState = {
    firstName: "",
    lastName: "",
    age: "",
    selectedDate: null,
    gender: "",
    standard: "",
    board: "",
    difficulties: [],
    files: [],
    displayName: "",
    currentSchoolName: "",
    hobby: "",
    interest: "",
    favSubject: "",
    weekSubject: "",
  };

  const handleParentRegistration = async () => {
    try {
      switch (true) {
        case visibleName === "":
          toast.error("Visible name is required");
          return;
        case country === "":
          toast.error("Country is required");
          return;
        case city === "":
          toast.error("City is required");
          return;
        case currency === "":
          toast.error("Currency is required");
          return;
        case communicationMode === "":
          toast.error("Communication mode is required");
          return;
      }
      setLoading(true);

      const response = await axios.put("/parent/register", {
        id: userData._id,
        visibleName: visibleName,
        country: country,
        pincode: pinCode || myPinCode,
        city: city,
        state: selectedState,
        district: selectedDistrict,
        address: address || getFullAddress(),
        currency: currency,
        communicationMode: communicationMode,
        timeZone: timezone || myTimeZone,
      });

      // console.log(response.data);

      if (response.data.res_type === "success") {
        toast.success(response.data.message);
        nextStep();
      } else {
        toast.error("Parent registration failed. please try again.");
      }
    } catch (error) {
      console.error("Error registering parent:", error);
    } finally {
      setLoading(false);
    }
  };

  const nextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const previousStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const [children, setChildren] = useState([initialChildState]);

  const handleAddChild = () => {
    setChildren([...children, { ...initialChildState }]);
  };

  const handleNext = () => {
    nextStep();
  };

  const handleInputChange = (index, field, value) => {
    const updatedChildren = [...children];
    updatedChildren[index][field] = value;
    setChildren(updatedChildren);
  };

  const handleDateChange = (index, date) => {
    const updatedChildren = [...children];
    updatedChildren[index]["selectedDate"] = date;
    updatedChildren[index]["age"] = calculateAgeFromDate(date);
    setChildren(updatedChildren);
  };

  function calculateAgeFromDate(dateOfBirth) {
    if (!dateOfBirth) {
      throw new Error("Date of birth is required.");
    }
  
    const birthDate = new Date(dateOfBirth);
    const currentDate = new Date();
  
    if (birthDate > currentDate) {
      throw new Error("Date of birth cannot be in the future.");
    }
  
    // Calculate age
    let age = currentDate.getFullYear() - birthDate.getFullYear();
  
    // Adjust age if the birthday hasn't occurred this year
    const hasBirthdayPassed =
      currentDate.getMonth() > birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() >= birthDate.getDate());
  
    if (!hasBirthdayPassed) {
      age -= 1;
    }
  
    return age;
  }

  //###===>> as due to added field in files the backend multer is not able to see it as binary
  const handleFileChange = (index, droppedFiles) => {
    const updatedChildren = [...children];
    // const processedFiles = droppedFiles.map((file) => ({
    //   ...file,
    //   preview: URL.createObjectURL(file),
    // }));
    // updatedChildren[index]["files"] = processedFiles;
    updatedChildren[index]["files"] = droppedFiles;
    setChildren(updatedChildren);
  };

  const handleAddDifficulty = (index, newDifficulties) => {
    const updatedChildren = [...children];
    updatedChildren[index]["difficulties"] = [...newDifficulties];
    setChildren(updatedChildren);
  };

  const handleRemoveDeficulties = (childIndex, difficultiesIndex) => {
    const updatedChildren = [...children];
    updatedChildren[childIndex]["difficulties"].splice(difficultiesIndex, 1);
    setChildren(updatedChildren);
  };

  const handleRegistration = () => {
    navigate("/parent/dashboard");
    // Show SweetAlert confirmation
    Swal.fire({
      icon: "success",
      title: "Registration Successful!",
      text: "Sawal: Have you received your confirmation email?",
    });
  };

  const handleAddChildren = async () => {
    const formData = new FormData();
    let data_arr = [];

    children.forEach((child, index) => {
      if (child.files && child.files?.length > 0) {
        console.log(`Appending file for child ${index}:`, child.files[0]);
        formData.append("image", child.files[0]);
      } else {
        console.warn(`No file found for child ${index}`);
      }
      data_arr.push({
        firstName: child.firstName,
        lastName: child.lastName,
        dob: child.selectedDate,
        age: child.age,
        favoriteSubject: child.favSubject,
        weekSubject: child.weekSubject,
        gender: child.gender,
        standard: child.standard,
        board: child.board,
        learningDeficulties: "None",
        displayedName: child.displayName,
        currentSchool: child.currentSchoolName,
        hobby: child.hobby,
        interest: child.interest,
        courses: [],
      });
    });

    formData.append("childrenData", JSON.stringify(data_arr));

    // Log FormData content
    for (let pair of formData.entries()) {
      console.log(`${pair[0]}: `, pair[1]);
    }

    try {
      setLoading(true);
      const response = await axios.post(
        `/parent/addChildren/${userData._id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log(response.data);

      // console.log(response.data);

      if (response.data.res_type === "success") {
        toast.success(response.data.message);
        setCurrentStep(currentStep + 1);
        navigate("/parent/dashboard");
        Swal.fire({
          icon: "success",
          title: "Registration Successful!",
          text: "Sawal: Have you received your confirmation email?",
        });
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("An error occurred while adding children");
      console.error("Error adding children:", error);
    } finally {
      setLoading(false);
    }
  };


  //auto fetch all country
  const [allCountry, setAllCountry] = useState([]); // List of all countries
  const [gSelectedCountry, setGSelectedCountry] = useState(""); // Selected country geonameId
  const [gSelectedCountryCode, setGSelectedCountryCode] = useState(""); // Selected country geonameId

  const [allStates, setAllStates] = useState([]); // List of all states in the selected country
  const [gSelectedState, setGSelectedState] = useState(""); // Selected state geonameId
  const [selectedState, setSelectedState] = useState(""); // Selected state geonameId

  const [allDistricts, setAllDistricts] = useState([]); // List of all districts in the selected state
  const [gSelectedDistrict, setGSelectedDistrict] = useState(""); // Selected district geonameId
  const [selectedDistrict, setSelectedDistrict] = useState(""); // Selected district geonameId

  const [allCities, setAllCities] = useState([]); // List of all cities in the selected district

  const [Lng, setLng] = useState('')
  const [Lat, setLat] = useState('')
  const [myTimeZone, setMyTimeZone] = useState('')
  const [myPinCode, setMyPinCode] = useState('')

  // Fetch all countries
  const fetchCountries = async () => {
    try {
      const res = await MyAPI.GET(
        "/home/country-info"
      );

      const { geonames, message } = res.data || {};

      if (geonames && geonames.length > 0) {
        setAllCountry(geonames);
      } else {
        toast.error(message || "Server Error. Please try again later.");
      }
    } catch (err) {
      toast.error(err.message || "Failed to fetch countries.");
    }
  };

  // Fetch all states for a given country geonameId
  const fetchStates = async (geonameId) => {
    try {
      const res = await axios.get(
        `/home/state-info/${geonameId}`
      );
      const { geonames, message } = res.data || {};

      if (geonames && geonames.length > 0) {
        setAllStates(geonames);
      } else {
        setAllStates([]);
        toast.error(message || "No states found.");
      }
    } catch (err) {
      toast.error(err.message || "Failed to fetch states.");
    }
  };

  // Fetch all districts for a given state geonameId
  const fetchDistricts = async (geonameId) => {
    try {
      const res = await MyAPI.GET(
        `/home/district-info/${geonameId}`
      );

      const { geonames, message } = res.data || {};

      if (geonames && geonames.length > 0) {
        setAllDistricts(geonames);
      } else {
        setAllDistricts([]);
        toast.error(message || "No districts found.");
      }
    } catch (err) {
      toast.error(err.message || "Failed to fetch districts.");
    }
  };

  // Fetch all cities for a given district geonameId
  const fetchCities = async (geonameId) => {
    try {
      const res = await MyAPI.GET(
              `/home/district-info/${geonameId}`
            );

      const { geonames, message } = res.data || {};

      if (geonames && geonames.length > 0) {
        setAllCities(geonames);
      } else {
        setAllCities([]);
        toast.error(message || "No cities found.");
      }
    } catch (err) {
      toast.error(err.message || "Failed to fetch cities.");
    }
  };

  const fetchTimezone = async (Lng, Lat) => {
    try {
      let res = await MyAPI.GET(`/home/timezone-info/${Lat}/${Lng}`);
      let { timezoneId, countryName } = res.data || res;
      if (timezoneId) {
        setTimezone(timezoneId)
        setMyTimeZone(timezoneId)
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const fetchPinCode = async (city, countryCode) => {
    try {
      let res = await MyAPI.GET(`/home/zip-info/${city}/${countryCode}`);
      let { postalCodes } = res.data || res;
      if (postalCodes && postalCodes.length > 0) {
        setPinCode(postalCodes[0].postalCode)
        setMyPinCode(postalCodes[0].postalCode)
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  // Load countries on component mount
  useEffect(() => {
    fetchCountries();
  }, []);

  // Load states when a country is selected
  useEffect(() => {
    if (gSelectedCountry) {
      fetchStates(gSelectedCountry);
    }
  }, [gSelectedCountry]);

  // Load districts when a state is selected
  useEffect(() => {
    if (gSelectedState) {
      fetchDistricts(gSelectedState);
    }
  }, [gSelectedState]);

  // Load cities when a district is selected
  useEffect(() => {
    if (gSelectedDistrict) {
      fetchCities(gSelectedDistrict);
    }
  }, [gSelectedDistrict]);

  useEffect(() => {
    if (Lat && Lng) {
      fetchTimezone(Lng, Lat)
    }
  }, [Lat, Lng])

  useEffect(() => {
    if (city && gSelectedCountryCode) {
      fetchPinCode(city, gSelectedCountryCode)
    }
  }, [city, gSelectedCountryCode])

  //for timezone fetch
  const [allCountries, setAllCountries] = useState([])

  useEffect(() => {
    const allCountries = countries();
    setAllCountries(allCountries);
  }, [token]);

  const getFullAddress = () => {
    let myAddress = `${address ? address : ''} ${city ? city : ''} ${selectedDistrict ? selectedDistrict : ''} ${selectedState ? selectedState : ''} ${country ? country : ''} ${myPinCode ? myPinCode : ''}`;
    return myAddress.trim();
  };

  // for creating user name
  function createUsername(email) {
    // Extract the username part (before '@')
    const username = email.split('@')[0];

    // Extract the domain part (after '@')
    const domain = email.split('@')[1];

    // Get the first two characters of the domain (service provider)
    const serviceProvider = domain ? domain.split('.')[0].slice(0, 2) : '';

    // Combine the username with the service provider abbreviation
    const generatedUsername = `${username}${serviceProvider}`;

    return generatedUsername;
  }

  useEffect(() => {
    if (email) {
      setVisibleName(createUsername(email))
    }
  }, [email])


  // ------------------------------------------------ step 2 ---------------------------------------

  const [allGrades, setAllGrades] = useState([])
  const [allBords, setAllBords] = useState([])
  const [allSubject, setAllSubject] = useState([])

  const fetchRecords = async (type) => {
    // console.log(type);

    try {
      setLoading(true);
      let res = await MyAPI.GET(
        `/common/records?title=&record_type=${type}&status=`,
        token
      );

      let { res_type, message, data, error } = res.data || res;

      if (res_type === "success") {
        if (type === 'board') {
          setAllBords(data)
        } else if (type === 'grade') {
          setAllGrades(data)
        } else {
          setAllSubject(data)
        }
      } else {
        toast.error(message || error || error.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally{
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRecords('board')
    fetchRecords('subject')
    fetchRecords('grade')
  }, [])

  return (
    <OftadehLayout>
      <h1>Registration</h1>
      {/* <OftadehBreadcrumbs path={history} /> */}

      <Col
        sm={12}
        md={12}
        className="mb-md-5 mb-3 d-flex align-items-center justify-content-between"
      >
        <Col sm={4} md={4} className="m-0 p-0 ps-2 step-container">
          <div
            className={
              currentStep >= 1
                ? "step-box registration-status-here"
                : "step-box"
            }
          >
            1
          </div>
          <div
            className={
              currentStep >= 2
                ? "progress-line registration-status-here"
                : "progress-line"
            }
          ></div>
        </Col>
        <Col sm={4} md={4} className="m-0 p-0 step-container">
          <div
            className={
              currentStep >= 2
                ? "step-box registration-status-here"
                : "step-box"
            }
          >
            2
          </div>
          <div
            className={
              currentStep >= 3
                ? "progress-line registration-status-here"
                : "progress-line"
            }
          ></div>
        </Col>
        <Col sm={4} md={4} className="m-0 p-0 step-container">
          <div
            className={
              currentStep >= 3
                ? "step-box registration-status-here"
                : "step-box"
            }
          >
            3
          </div>
          <div
            className={
              currentStep >= 4
                ? "progress-line registration-status-here"
                : "progress-line"
            }
          ></div>
        </Col>
      </Col>

      {currentStep === 1 && (
        <Row className="d-flex mt-3">
          <div class="form-group col-md-4 mt-md-3 mt-sm-3">
            <label for="phone">Parent I'D</label>
            <input
              disabled
              type="text"
              class="form-control  border-yellow rounded-5"
              id="LastName"
              value={userData?.uid}
            />
          </div>
          <div class="form-group col-md-4 mt-sm-3">
            <label for="FirstName">first name</label>
            <input
              disabled
              value={firstName}
              type="text"
              class="form-control border-yellow rounded-5"
              id="FirstName"
              placeholder="First Name"
            />
          </div>
          <div class="form-group col-md-4 mt-sm-3">
            <label for="LastName">last name</label>
            <input
              disabled
              value={lastName}
              type="text"
              class="form-control  border-yellow rounded-5"
              id="LastName"
              placeholder="Last Name"
            />
          </div>
          <div class="form-group col-md-4 mt-md-3 mt-sm-3">
            <label for="phone">Visible Name</label>
            <input
              type="text"
              class="form-control border-yellow rounded-5"
              id="LastName"
              placeholder="Enter visible name"
              value={visibleName}
              onChange={(e) => setVisibleName(e.target.value)}
            />
          </div>
          <div class="form-group col-md-4 mt-md-3 mt-sm-3">
            <label for="inputEmail4">Email</label>
            <input
              disabled
              value={email}
              type="email"
              class="form-control  border-yellow rounded-5"
              id="inputEmail4"
              placeholder="Email"
            />
          </div>
          <Col md={4} className="mt-3 mb-3 ">
            <label for="phone">Phone</label>
            <div className="px-3 py-2 rounded-5 border-yellow">
              <PhoneInput
                international
                style={{ border: "none !important" }}
                defaultCountry="IN"
                placeholder="Enter phone number"
                value={`${userData?.phone}`}
                disabled
              />
            </div>
          </Col>
          <Col md={4} className="mt-3 mb-3 ">
            <label for="phone">Whatsapp No.</label>
            <div className="px-3 py-2 rounded-5 border-yellow">
              <PhoneInput
                international
                style={{ border: "none !important" }}
                defaultCountry="IN"
                placeholder="Enter phone number"
                value={`${userData?.whatsapp}`}
                onChange={setWpNumber}
              />
            </div>
          </Col>
          <Col md={4}>
            <Form.Group controlId="selectOption">
              <Form.Label>Select Country</Form.Label>
              <Form.Select
                className="rounded-5 border-yellow"
                onChange={(e) => {
                  const selectedCountryCode = e.target.value;
                  const selectedCountry = allCountry.find(
                    (country) => country.geonameId == selectedCountryCode
                  );
                  setCountry(selectedCountry.countryName); // Set the selected country code
                  setGSelectedCountry(selectedCountry ? selectedCountry.geonameId : "");
                  setGSelectedCountryCode(selectedCountry.countryCode);
                  setCurrency(selectedCountry.currencyCode)
                }}
                value={gSelectedCountry} // The selected country code
              >
                <option>Select Country</option>
                {allCountry &&
                  allCountry.map((country) => (
                    <option key={country.geonameId} value={country.geonameId}>
                      {country.countryName}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="selectOption">
              <Form.Label>Select State</Form.Label>
              <Form.Select
                className="rounded-5 border-yellow"
                onChange={(e) => {
                  const selectedStateCode = e.target.value;
                  setGSelectedState(selectedStateCode); // Set selected state code
                  const selectedState = allStates.find(
                    (state) => state.geonameId == selectedStateCode
                  );
                  setSelectedState(selectedState ? selectedState.name : ""); // Set selected state name or empty string
                }}
                value={gSelectedState} // The selected state code
                disabled={!allStates.length} // Disable if no states are available
              >
                <option value="">Select state</option>
                {allStates &&
                  allStates.map((state) => (
                    <option key={state.geonameId} value={state.geonameId}>
                      {state.name}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group controlId="selectOption">
              <Form.Label>Select District</Form.Label>
              <Form.Select
                className="rounded-5 border-yellow"
                value={gSelectedDistrict}
                onChange={(e) => {
                  const selectedDistCode = e.target.value;
                  setGSelectedDistrict(selectedDistCode); // Set selected district code
                  const selectedDistrict = allDistricts.find(
                    (dist) => dist.geonameId == selectedDistCode
                  );
                  setSelectedDistrict(selectedDistrict ? selectedDistrict.name : ""); // Set selected district name or empty string
                  setLng(selectedDistrict.lng)
                  setLat(selectedDistrict.lat)
                }}
                disabled={!allDistricts.length} // Disable if no districts are available
              >
                <option value="">Select district</option>
                {allDistricts.map((district) => (
                  <option key={district.geonameId} value={district.geonameId}>
                    {district.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          {
            allCities.length ? (
              <Col md={4}>
                <Form.Group controlId="selectOption">
                  <Form.Label>Select City</Form.Label>
                  <Form.Select
                    className="rounded-5 border-yellow"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    disabled={!allCities.length}
                  >
                    <option value="">Select City</option>
                    {allCities.map((district) => (
                      <option key={district.geonameId} value={district.name}>
                        {district.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            ) : (
              <div class="form-group col-md-4 mt-md-3 mt-sm-3">
                <label for="phone">Enter City</label>
                <input
                  type="text"
                  class="form-control  border-yellow rounded-5"
                  id="LastName"
                  placeholder="Enter City"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>
            )
          }
          <div class="form-group col-md-4 mt-md-3 mt-sm-3">
            <label for="phone">Pin code</label>
            <input
              value={pinCode}
              onChange={(e) => setPinCode(e.target.value)}
              type="text"
              class="form-control  border-yellow rounded-5"
              id="LastName"
              placeholder="Enter pin code."
            />
          </div>
          <Form.Group className="col-md-12 mt-md-12 mt-sm-12" controlId="LastName">
            <Form.Label>Enter Address</Form.Label>
            <Form.Control
              as="textarea"
              className="border-yellow rounded-2"
              placeholder="Enter Address"
              value={address || getFullAddress()}
              onChange={(e) => setAddress(e.target.value)}
            />
          </Form.Group>
          <Col md={4} className="mt-md-3 mt-sm-3">
            <Form.Group controlId="selectOption">
              <Form.Label>Select currency</Form.Label>
              <Form.Select
                className="rounded-5 border-yellow"
                onChange={(e) => setCurrency(e.target.value)}
                value={currency}
              >
                <option value="">Select Currency</option>
                {allCountry &&
                  allCountry.map((country) => (
                    <option key={country.geonameId} value={country.currencyCode}>
                      {country.currencyCode}
                    </option>
                  ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={4} className="mt-md-3 mt-sm-3">
            <Form.Group controlId="selectOption">
              <Form.Label>Select Communication mode.</Form.Label>
              <Form.Select
                className="rounded-5 border-yellow"
                onChange={(e) => setCommunicationMode(e.target.value)}
                value={communicationMode}
              >
                <option value="">Select</option>
                <option value="Email">Email</option>
                <option value="Phone">Phone</option>
                <option value="Whatsapp">Whatsapp</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={4} className="mt-md-3 mt-sm-3">
            <Form.Group controlId="selectOption">
              <Form.Label>Select Timezone</Form.Label>
              <Form.Select
                className="rounded-5 border-yellow"
                onChange={(e) => setTimezone(e.target.value)}
                value={timezone || myTimeZone}
              >
                <option value="">Select</option>
                {allCountries.map((country) =>
                  country?.tz.map((tz) => (
                    <option key={tz} value={tz}>
                      {tz}
                    </option>
                  ))
                )}
              </Form.Select>
            </Form.Group>
          </Col>
          <div class="form-group mt-4 mb-3 mt-sm-3">
            <div class="form-check">&nbsp;</div>
          </div>
          <button
            onClick={handleParentRegistration}
            type="button"
            style={{ width: "fit-content" }}
            class="ms-2 btn teacher-button rounded-5 text-white border-0"
          >
            {loading ? "Loading..." : "Next"}
          </button>
        </Row>
      )}

      {currentStep === 2 && (
        <Form onSubmit={handleAddChildren}>
          {children.map((child, index) => (
            <Row className="mt-3" key={index}>
              <Col md={12}>
                {" "}
                <h4>Child {index + 1} Details</h4>
              </Col>
              <div className="form-group col-md-4 mt-sm-3">
                <label htmlFor="FirstName">First name</label>
                <input
                  value={child.firstName}
                  onChange={(e) =>
                    handleInputChange(index, "firstName", e.target.value)
                  }
                  type="text"
                  className="form-control rounded-5 border-yellow"
                  id="FirstName"
                  placeholder="First Name"
                  required
                />
              </div>
              <div className="form-group col-md-4 mt-sm-3">
                <label htmlFor="LastName">Last name</label>
                <input
                  value={child.lastName}
                  onChange={(e) =>
                    handleInputChange(index, "lastName", e.target.value)
                  }
                  type="text"
                  className="form-control rounded-5 border-yellow"
                  id="LastName"
                  placeholder="Last Name"
                  required
                />
              </div>
              <Col className="mt-sm-3" md={4}>
                <Form.Group controlId="nameInput">
                  <Form.Label>Date of birth</Form.Label>
                  <Form.Control
                    className="border-yellow rounded-5"
                    type="date"
                    value={child.selectedDate}
                    onChange={(e) => handleDateChange(index, e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
              <div className="form-group col-md-4 mt-sm-3">
                <label htmlFor="Age">Age</label>
                <input
                  value={child.age}
                  onChange={(e) =>
                    handleInputChange(index, "age", e.target.value)
                  }
                  type="text"
                  className="form-control rounded-5 border-yellow"
                  id="Age"
                  placeholder="Age"
                  required
                />
              </div>
              <Col md={4} className="mt-3">
                <Form.Group controlId="selectOption">
                  <Form.Label>Favorite Subject</Form.Label>
                  <Form.Select
                    className="rounded-5 border-yellow"
                    value={child.favSubject}
                    onChange={(e) =>
                      handleInputChange(index, "favSubject", e.target.value)
                    }
                    required
                  >
                    <option value="">Select</option>
                    {
                      allSubject && allSubject.length > 0 && allSubject.map((subject, key) => (
                        <option key={key} value={subject.title}>{subject.title}</option>
                      ))
                    }
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={4} className="mt-3">
                <Form.Group controlId="selectOption">
                  <Form.Label>Week Subject</Form.Label>
                  <Form.Select
                    className="rounded-5 border-yellow"
                    value={child.weekSubject}
                    onChange={(e) =>
                      handleInputChange(index, "weekSubject", e.target.value)
                    }
                    required
                  >
                    <option value="">Select</option>
                    {
                      allSubject && allSubject.length > 0 && allSubject.map((subject, key) => (
                        <option key={key} value={subject.title}>{subject.title}</option>
                      ))
                    }
                  </Form.Select>
                </Form.Group>
              </Col>
              <div className="form-group col-md-4 mt-sm-3">
                <label htmlFor="gender">Gender</label>
                <select
                  value={child.gender}
                  onChange={(e) =>
                    handleInputChange(index, "gender", e.target.value)
                  }
                  className="form-control border-yellow rounded-5"
                  id="gender"
                  required
                >
                  <option value="" disabled>
                    Select gender
                  </option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>

              <div className="form-group col-md-4 mt-sm-3">
                <label htmlFor="standard">Standard</label>
                <select
                  value={child.standard}
                  onChange={(e) =>
                    handleInputChange(index, "standard", e.target.value)
                  }
                  className="form-control border-yellow rounded-5"
                  id="standard"
                  required
                >
                  <option value="" disabled>
                    Select Standard
                  </option>
                  {
                    allGrades && allGrades.length > 0 && allGrades.map((grade, key) => (
                      <option key={key} value={grade.title}>{grade.title}</option>
                    ))
                  }
                </select>
              </div>
              <div className="form-group col-md-4 mt-sm-3">
                <label htmlFor="Board">Board</label>
                <select
                  value={child.board}
                  onChange={(e) =>
                    handleInputChange(index, "board", e.target.value)
                  }
                  className="form-control border-yellow rounded-5"
                  id="Board"
                  required
                >
                  <option value="" disabled>
                    Select Board
                  </option>
                  {
                    allBords && allBords.length > 0 && allBords.map((board, key) => (
                      <option key={key} value={board.title}>{board.title}</option>
                    ))
                  }
                </select>
              </div>

              {/* <div className="mt-2">
                <LearningDifficultiesInput
                  onAddDifficulty={(newDifficulties) =>
                    handleAddDifficulty(index, newDifficulties)
                  }
                  difficulties={child.difficulties}
                />
                <div className="d-flex gap-2 mt-2">
                  {child.difficulties.map((difficulty, key) => (
                    <span
                      className="px-2 py-1 rounded-3 border d-flex align-items-center"
                      key={key}
                    >
                      {difficulty}
                      <IoMdCloseCircle
                        onClick={() => handleRemoveDeficulties(index, key)}
                        color="red"
                        className="cursor-pointer"
                        size={22}
                      />
                    </span>
                  ))}
                </div>
              </div> */}

              <div className="form-group col-md-4 mt-sm-3">
                <label htmlFor="DisplayName">Displayed name</label>
                <input
                  value={child.displayName}
                  onChange={(e) =>
                    handleInputChange(index, "displayName", e.target.value)
                  }
                  type="text"
                  className="form-control rounded-5 border-yellow"
                  id="DisplayName"
                  placeholder="Display Name"
                  required
                />
              </div>
              <div className="form-group col-md-4 mt-sm-3">
                <label htmlFor="currentSchoolName">Current School</label>
                <input
                  value={child.currentSchoolName}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      "currentSchoolName",
                      e.target.value
                    )
                  }
                  type="text"
                  className="form-control rounded-5 border-yellow"
                  id="currentSchoolName"
                  placeholder="Current School Name"
                  required
                />
              </div>
              <div className="form-group col-md-4 mt-sm-3">
                <label htmlFor="Hobby">Hobby</label>
                <input
                  value={child.hobby}
                  onChange={(e) =>
                    handleInputChange(index, "hobby", e.target.value)
                  }
                  type="text"
                  className="form-control rounded-5 border-yellow"
                  id="Hobby"
                  placeholder="Hobby"
                  required
                />
              </div>
              <div className="form-group col-md-4 mt-sm-3">
                <label htmlFor="Interest">Interest</label>
                <input
                  value={child.interest}
                  onChange={(e) =>
                    handleInputChange(index, "interest", e.target.value)
                  }
                  type="text"
                  className="form-control rounded-5 border-yellow"
                  id="Interest"
                  placeholder="Interest"
                  required
                />
              </div>
              <Row className="mt-4">
                <span className="mb-2">Upload your child image</span>
                <br />
                <Dropzone
                  onDrop={(acceptedFiles) =>
                    handleFileChange(index, acceptedFiles)
                  }
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()} className="dropzone">
                      <input {...getInputProps()} />
                      <p>
                        Drag 'n' drop some files here, or click to select files
                      </p>
                    </div>
                  )}
                </Dropzone>

                <Col
                  md={6}
                  className="p-3"
                  style={{ minHeight: "20vh", overflowY: "auto" }}
                >
                  {child.files?.length > 0 ? (
                    child.files.map((file) => (
                      <div className="pt-2 me-2" key={file.path}>
                        {file.preview && (
                          <img
                            className="rounded-2"
                            src={file.preview}
                            alt={file.path}
                            style={{ width: "50px", height: "50px" }}
                          />
                        )}
                        <span className="ms-2">{file.path}</span>
                      </div>
                    ))
                  ) : (
                    <p>No Files Uploaded.</p>
                  )}
                </Col>
              </Row>
            </Row>
          ))}
          <Row className="mt-3">
            <Button
              className="me-1 teacher-button text-white rounded-5 border-0 d-flex align-items-center justify-content-center"
              style={{ width: "fit-content" }}
              variant="primary"
              onClick={() => setCurrentStep(currentStep - 1)}
            >
              <GrCaretPrevious />
            </Button>
            <Button
              className="teacher-button rounded-5 border-0 text-white"
              style={{ width: "fit-content" }}
              variant="outline-primary"
              onClick={handleAddChild}
              disabled={children?.length >= 4 ? true : false}
            >
              <IoAdd /> Add one more Child
            </Button>

            <Button
              className="teacher-button text-white rounded-5 ms-2 border-0"
              style={{ width: "fit-content" }}
              variant="primary"
              type="submit"
            >
              {loading ? "Loading..." : "Next"}
            </Button>
          </Row>
        </Form>
      )}

      {currentStep === 3 && (
        <Row className="mt-3">
          <Col md={12}>
            {" "}
            <h4>Additional Information</h4>
          </Col>
          <div className="form-group col-md-4 mt-sm-3">
            <label htmlFor="HowToYouReferHere">&nbsp;</label>
            <select
              value={HowToYouReferHere}
              id="HowToYouReferHere"
              onChange={(e) => setHowToYouReferHere(e.target.value)}
              className="form-control border-yellow rounded-5"
              style={{ width: "100%", height: "6vh" }}
            >
              <option value="" disabled selected>
                How did you hear about us.
              </option>
              <option value="good">Social Media</option>
              <option value="low">Offline Marketing</option>
              <option value="low">Online Marketing</option>
              <option value="poor">Friends</option>
              <option value="poor">Teachers</option>
              <option value="poor">Google Search</option>
              <option value="poor">Other</option>
            </select>
          </div>
          <Col className="mt-3" xs={12}>
            <textarea
              value={AnySuggestion}
              onChange={(e) => setAnySuggestion(e.target.value)}
              className="form-control border-yellow rounded-3"
              rows="4"
              placeholder="Enter any suggestion here..."
              style={{ width: "100%" }}
              required
            ></textarea>
          </Col>
          <div class="form-group mt-4 mb-3 mt-sm-3">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id="termandservice"
                required
              />
              <label class="form-check-label" for="termandservice">
                accept term & service.
              </label>
            </div>
          </div>
          <Row className="ps-4 mt-3">
            <Button
              className="me-1 teacher-button text-white rounded-5 border-0 d-flex align-items-center justify-content-center"
              style={{ width: "fit-content" }}
              variant="primary"
              onClick={previousStep}
            >
              <GrCaretPrevious />
            </Button>
            <Button
              // onClick={handleSubmitData}
              onClick={handleRegistration}
              className="teacher-button text-white rounded-5 border-0"
              style={{ width: "fit-content" }}
              variant="primary"
            >
              Submit
            </Button>
          </Row>
        </Row>
      )}
      <ParentFooter />
    </OftadehLayout>
  );
}

export default ParentRegistration;
